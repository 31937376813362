<template>
  <div :class="[$style.root, appliedFilters.length && $style.hasFilters]">
    <!-- !!!!- {{ JSON.stringify(selectedTracksForDownload) }} -->
    <div :class="$style.tableView" ref="tableView">
      <div :class="$style.tableContainer" ref="tableContainer">
        <div :class="$style.tableMarketTabs">
          <BaseButton
            :class="[
              $style.marketTypeButton,
              selectedMarket === MARKET_TYPE.ALL && $style.inactiveMarket,
            ]"
            @click.prevent="selectedMarket = MARKET_TYPE.TOP"
          >
            TOP MARKETS
          </BaseButton>
          <BaseButton
            :class="[
              $style.marketTypeButton,
              selectedMarket === MARKET_TYPE.TOP && $style.inactiveMarket,
            ]"
            @click.prevent="selectedMarket = MARKET_TYPE.ALL"
          >
            ALL MARKETS
          </BaseButton>
        </div>
        <table :class="$style.tracksTable">
          <thead
            :class="[
              $style.tableHead,
              tableViewVerticalScrolled && $style.scrolled,
            ]"
          >
            <tr ref="tableHeadRow" :class="[$style.tableRow]">
              <th
                :class="[
                  $style.tableHeading,
                  selectedMarket === MARKET_TYPE.TOP && $style.topMarket,
                ]"
                v-for="{ source, sourceShort } of selectedSourcesTableHeaders"
                :key="source"
                :title="source"
              >
                {{ sourceShort }}
              </th>
            </tr>
          </thead>
          <SourcesTableBody
            :tbodyClass="$style.tableBody"
            :tracks="tracksWithSources"
            :tracksBySource="tracksBySource"
            :selectedTracksForDownloadCount="selectedTracksForDownloadCount"
            :selectedTracksForDownload="selectedTracksForDownload"
            :selectedMarket="selectedMarket"
            :marketType="MARKET_TYPE"
            @openCountryDetails="$emit('openCountryDetails', $event)"
            @onTrackSelectChange="onTrackSelectChange"
          />
          <LoadMore
            v-if="tracks.length"
            tag="tr"
            @loadMore="!isLastPage && $emit('loadMoreTracks')"
          />
        </table>
        <div ref="loadMoreColumns"></div>
      </div>
    </div>
    <footer :class="$style.footer">
      <div :class="$style.selectedTracksActions">
        <router-link
          v-show="tracks && selectedTracksForDownloadCount === 0"
          :class="$style.viewLink"
          :to="{
            path: `/${
              this.isDemo ? `d/${this.$route.params.algolia_index}` : 'catalogs'
            }/search/map`,
            query: this.$route.query,
            params: this.$route.params,
          }"
          ><BaseIcon :class="$style.viewLinkIcon" icon="map" />Map
          View</router-link
        >
        <BaseButton
          v-if="selectedTracksForDownloadCount > 0"
          :class="[$style.viewLink, $style.downloadTracksBtn]"
          @click="$emit('downloadSelectedTracks')"
          :disabled="downloadStatus.downloadSelectedTracksStatusPending"
        >
          {{
            downloadStatus.downloadSelectedTracksStatusPending
              ? `Downloading tracks...`
              : `Download Selected (${selectedTracksForDownloadCount})`
          }}
        </BaseButton>
        <BaseButton
          v-if="allRegistrationsCount && !selectedTrack"
          :disabled="downloadStatus.downloadSelectedTracksStatusPending"
          @click="$emit('downloadAllRegistrations')"
        >
          {{
            downloadStatus.downloadSelectedTracksStatusPending
              ? `Downloading tracks...`
              : `Download All (${allRegistrationsCount})`
          }}
        </BaseButton>
        <!-- <BaseButton
          v-if="selectedTracksForDownloadCount > 0 && !selectedTrack"
          :class="[$style.viewLink, $style.downloadTracksBtn]"
          @click="$emit('showTracksListModal')"
        >
          {{ `Save Selected (${selectedTracksForDownloadCount})` }}
        </BaseButton> -->
      </div>
      <StatusLegend />
    </footer>
  </div>
</template>
<script>
import SourceStatus from './components/SourceStatus';
import SourcesTableBody from './components/SourcesTableBody';
import StatusLegend from './components/StatusLegend';
import { syncScroll } from '@/services/syncScroll';
import { prepTracksWithSources } from '../helpers/prepTracksWithSources';
import LoadMore from '@/components/common/LoadMore';
import Footer from '../../components/Footer.vue';
import IconCheckbox from './components/IconCheckbox';

const MARKET_TYPE = {
  ALL: 'ALL',
  TOP: 'TOP',
};

const MARKET_ALL_PER_PAGE = 15;

export default {
  name: 'CatalogTableView',
  components: {
    SourceStatus,
    SourcesTableBody,
    StatusLegend,
    LoadMore,
    Footer,
    IconCheckbox,
  },
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
    distinctTracks: {
      type: Array,
      default: () => [],
    },
    selectedTrack: {
      type: Object,
    },
    tracksBySource: {
      type: Object,
      default: () => ({}),
    },
    catalogId: {
      type: String,
      required: false,
    },
    sources: {
      type: Array,
      default: () => [],
    },
    topSources: {
      type: Array,
      default: () => [],
    },
    isLastPage: {
      type: Boolean,
      default: false,
    },
    selectedTracksForDownload: {
      type: Object,
      required: true,
    },
    addTrackForDownload: {
      type: Function,
      required: true,
    },
    removeTrackForDownload: {
      type: Function,
      required: true,
    },
    selectedTracksForDownloadCount: {
      type: Number,
      required: true,
    },
    downloadStatus: {
      type: Object,
      default: () => ({}),
    },
    allRegistrationsCount: {
      type: [String, Number],
      default: 0,
    },
    isDemo: {
      type: Boolean,
      default: false,
    },
    appliedFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedMarket: MARKET_TYPE.TOP,
      allSourcesPage: 1,
      tracksWithSources: {},
      tableViewVerticalScrolled: false,
    };
  },
  created() {
    this.MARKET_TYPE = MARKET_TYPE;
    this.cachedTracks = {
      top: null,
      all: null,
    };
  },
  mounted() {
    this.$refs.tableView.addEventListener(
      'scroll',
      this.onTableViewVerticalScroll
    );

    this.scrollSyncInstance = syncScroll(
      this.$refs.tableContainer,
      this.$refs.tableHeadRow
    );
  },
  computed: {
    selectedSources() {
      return this.selectedMarket === MARKET_TYPE.ALL
        ? this.sources
        : this.sources.filter(({ top_market }) => top_market === 'Yes');
    },
    selectedSourcesTableHeaders() {
      return this.selectedMarket === MARKET_TYPE.ALL
        ? this.sources.slice(0, this.allSourcesPage * MARKET_ALL_PER_PAGE)
        : this.sources.filter(({ top_market }) => top_market === 'Yes');
    },
    tracksWithAllSourcesSlice() {
      let tracks = {};
      const limit = this.allSourcesPage * 15;
      let counter = 0;
      for (const key in this.tracksWithAllSources) {
        if (counter === limit) break;
        tracks[key] = this.tracksWithAllSources[key];
        counter++;
      }

      return tracks;
    },
  },
  watch: {
    async selectedMarket(market) {
      this.$refs.tableContainer.scrollLeft = 0;
      this.prepareTracks(market);
    },
    distinctTracks: {
      immediate: true,
      async handler() {
        if (!this.distinctTracks.length) return;
        this.cachedTracks = {
          top: null,
          all: null,
        };
        this.prepareTracks(this.selectedMarket);
        await this.$nextTick();
        this.initLoadMoreColumns();
      },
    },
  },
  beforeDestroy() {
    this.loadMoreObserver?.disconnect();
    this.$refs.tableView.removeEventListener(
      'scroll',
      this.onTableViewVerticalScroll
    );

    this.scrollSyncInstance?.cleanup?.();
  },
  methods: {
    onTrackSelectChange({ checked, track }) {
      checked
        ? this.addTrackForDownload(track, 'objectID')
        : this.removeTrackForDownload(track, 'objectID');
    },
    onTableViewVerticalScroll(e) {
      this.tableViewVerticalScrolled = e.target.scrollTop > 0;
    },
    initLoadMoreColumns() {
      const $target = this.$refs.loadMoreColumns;
      let options = {
        root: this.$refs.tableContainer,
        rootMargin: '0px',
        threshold: 1.0,
      };

      const onIntersect = (entries) => {
        entries.forEach((entry) => {
          if (this.selectedMarket === MARKET_TYPE.TOP) return;
          this.allSourcesPage++;
          this.tracksWithSources = this.getTracksWithSlicedSources(
            this.cachedTracks.all
          );
        });
      };

      const observer = new IntersectionObserver(onIntersect, options);
      this.loadMoreObserver = observer;

      observer.observe($target);
    },
    prepareTracks(market) {
      // Prepare top market tracks only
      if (market === MARKET_TYPE.TOP) {
        this.allSourcesPage = 1;

        if (this.cachedTracks.top) {
          this.tracksWithSources = this.cachedTracks.top;
          return;
        }

        const topTracks = prepTracksWithSources({
          // tracks: this.tracks,
          tracks: this.distinctTracks,
          selectedSources: this.selectedSources,
        });
        this.tracksWithSources = topTracks;
        this.cachedTracks.top = topTracks;
        return;
      }
      this.allSourcesPage++;
      // Prepare all tracks
      const allTracks = prepTracksWithSources({
        tracks: this.tracks,
        selectedSources: this.selectedSources,
      });
      this.cachedTracks.all = allTracks;

      this.tracksWithSources = this.getTracksWithSlicedSources(allTracks);
    },
    getTracksWithSlicedSources(tracksWithSources) {
      let tracks = {};
      const limit = this.allSourcesPage * MARKET_ALL_PER_PAGE;

      for (const key in tracksWithSources) {
        tracks[key] = {
          ...tracksWithSources[key],
          sources: this.getSlicedSources(tracksWithSources[key].sources, limit),
        };
      }

      return tracks;
    },
    getSlicedSources(allSources, limit) {
      const sources = {};
      let counter = 0;

      for (const key in allSources) {
        if (counter === limit) break;
        sources[key] = allSources[key];
        counter++;
      }

      return sources;
    },
  },
};
</script>
<style lang="scss" module>
.root {
  position: relative;
  top: 10px;

  &.hasFilters {
    top: 20px;
  }
}
.tableMarketTabs {
  position: fixed;
  z-index: 60;
  left: 5rem;
  transform: translateY(0.1rem);
}
.marketTypeButton {
  padding: 0 !important;
  font-size: 1rem !important;

  &:first-of-type {
    margin-right: 2rem;
  }
}

.inactiveMarket {
  color: $color-grey-light #{!important};
}

.tableView {
  position: relative;
  height: calc(100vh - 18rem);
  overflow-y: auto;
}

.hasFilters {
  .tableView {
    height: calc(100vh - 22rem);
  }
}

.tableContainer {
  width: calc(100vw - 10rem - 360px);
  overflow-x: scroll;
  margin-left: 360px;
  display: flex;
  align-items: stretch;
}

.tracksTable {
  padding: 0;
}

.tableBody {
  & > tr:first-child {
    margin-top: 48px;
  }
}

.hasFilters {
  .tableHead {
    top: 254px;
  }
}

.tableHead {
  position: fixed;
  top: 184px;
  left: calc(5rem);
  z-index: 50;
  transform: translateY(-10px);
  max-width: calc(100vw - 10rem);
  &.scrolled {
    background: white;
  }

  .tableRow {
    margin-left: 360px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  // position: sticky;
  // top: 0;
}

// .tableHead {
//   height: 48px;
//   .tableRow {
//     position: fixed;
//     left: 5rem;
//     z-index: 5;
//     transform: translateY(-5px);
// &.scrolled {
//   background: white;
// }

// .tableHeading:nth-child(2) {
//   margin-left: 360px;
// }
//   }
// }

.tableRow {
  display: flex;
  height: 48px;
}

.tableHeading {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  width: 100px;
  white-space: nowrap;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  flex-shrink: 0;

  // &:first-child {
  //   margin-left: 360px;
  // }

  &.topMarket {
    width: calc((100vw - 550px) / 11);
  }
}

.tableCell {
  font-size: 14px;
  white-space: nowrap;
  padding: 0 2px;

  &:not(.tableFirstCol) {
    width: 100px;
    display: flex;
    justify-content: center;
  }
}

.tableFirstCol {
  // width: 360px;
  // font-weight: 500;
  // position: fixed;
  // top: auto;
  // left: 0;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // height: 48px;
  // z-index: 50;
}

.footer {
  margin: 3rem 5rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewLink {
  display: block;
  color: $color-grey-light;

  &:hover {
    color: #333;
  }
}

.selectedTracksActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.downloadTracksBtn {
  padding: 0 !important;
}

.viewLinkIcon {
  margin-right: 0.5rem;
}
</style>
