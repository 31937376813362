var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.root, _vm.appliedFilters.length && _vm.$style.hasFilters]},[_c('div',{ref:"tableView",class:_vm.$style.tableView},[_c('div',{ref:"tableContainer",class:_vm.$style.tableContainer},[_c('div',{class:_vm.$style.tableMarketTabs},[_c('BaseButton',{class:[
            _vm.$style.marketTypeButton,
            _vm.selectedMarket === _vm.MARKET_TYPE.ALL && _vm.$style.inactiveMarket ],on:{"click":function($event){$event.preventDefault();_vm.selectedMarket = _vm.MARKET_TYPE.TOP}}},[_vm._v(" TOP MARKETS ")]),_c('BaseButton',{class:[
            _vm.$style.marketTypeButton,
            _vm.selectedMarket === _vm.MARKET_TYPE.TOP && _vm.$style.inactiveMarket ],on:{"click":function($event){$event.preventDefault();_vm.selectedMarket = _vm.MARKET_TYPE.ALL}}},[_vm._v(" ALL MARKETS ")])],1),_c('table',{class:_vm.$style.tracksTable},[_c('thead',{class:[
            _vm.$style.tableHead,
            _vm.tableViewVerticalScrolled && _vm.$style.scrolled ]},[_c('tr',{ref:"tableHeadRow",class:[_vm.$style.tableRow]},_vm._l((_vm.selectedSourcesTableHeaders),function(ref){
          var source = ref.source;
          var sourceShort = ref.sourceShort;
return _c('th',{key:source,class:[
                _vm.$style.tableHeading,
                _vm.selectedMarket === _vm.MARKET_TYPE.TOP && _vm.$style.topMarket ],attrs:{"title":source}},[_vm._v(" "+_vm._s(sourceShort)+" ")])}),0)]),_c('SourcesTableBody',{attrs:{"tbodyClass":_vm.$style.tableBody,"tracks":_vm.tracksWithSources,"tracksBySource":_vm.tracksBySource,"selectedTracksForDownloadCount":_vm.selectedTracksForDownloadCount,"selectedTracksForDownload":_vm.selectedTracksForDownload,"selectedMarket":_vm.selectedMarket,"marketType":_vm.MARKET_TYPE},on:{"openCountryDetails":function($event){return _vm.$emit('openCountryDetails', $event)},"onTrackSelectChange":_vm.onTrackSelectChange}}),(_vm.tracks.length)?_c('LoadMore',{attrs:{"tag":"tr"},on:{"loadMore":function($event){!_vm.isLastPage && _vm.$emit('loadMoreTracks')}}}):_vm._e()],1),_c('div',{ref:"loadMoreColumns"})])]),_c('footer',{class:_vm.$style.footer},[_c('div',{class:_vm.$style.selectedTracksActions},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.tracks && _vm.selectedTracksForDownloadCount === 0),expression:"tracks && selectedTracksForDownloadCount === 0"}],class:_vm.$style.viewLink,attrs:{"to":{
          path: ("/" + (this.isDemo ? ("d/" + (this.$route.params.algolia_index)) : 'catalogs') + "/search/map"),
          query: this.$route.query,
          params: this.$route.params,
        }}},[_c('BaseIcon',{class:_vm.$style.viewLinkIcon,attrs:{"icon":"map"}}),_vm._v("Map View")],1),(_vm.selectedTracksForDownloadCount > 0)?_c('BaseButton',{class:[_vm.$style.viewLink, _vm.$style.downloadTracksBtn],attrs:{"disabled":_vm.downloadStatus.downloadSelectedTracksStatusPending},on:{"click":function($event){return _vm.$emit('downloadSelectedTracks')}}},[_vm._v(" "+_vm._s(_vm.downloadStatus.downloadSelectedTracksStatusPending ? "Downloading tracks..." : ("Download Selected (" + _vm.selectedTracksForDownloadCount + ")"))+" ")]):_vm._e(),(_vm.allRegistrationsCount && !_vm.selectedTrack)?_c('BaseButton',{attrs:{"disabled":_vm.downloadStatus.downloadSelectedTracksStatusPending},on:{"click":function($event){return _vm.$emit('downloadAllRegistrations')}}},[_vm._v(" "+_vm._s(_vm.downloadStatus.downloadSelectedTracksStatusPending ? "Downloading tracks..." : ("Download All (" + _vm.allRegistrationsCount + ")"))+" ")]):_vm._e()],1),_c('StatusLegend')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }