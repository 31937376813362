/**
 * Init the sync with two elements passed as params
 * On both elements, add scroll listeners. On scroll, update the scroll position of the other element
 * Have a method to remove event listeners
 */

export const syncScroll = ($el, $syncEl) => {
  const onScroll = (target) => (e) => {
    const $currentEl = e.target;
    const { scrollLeft, scrollTop } = $currentEl;
    target.scrollTo({
      top: scrollTop,
      left: scrollLeft,
    });
  };
  const onElScroll = onScroll($syncEl);
  const onSyncElScroll = onScroll($el);

  $el.addEventListener('scroll', onElScroll);
  $syncEl.addEventListener('scroll', onSyncElScroll);

  const cleanup = () => {
    $el.removeEventListener('scroll', onElScroll);
    $syncEl.removeEventListener('scroll', onSyncElScroll);
  };

  return {
    cleanup,
  };
};
